.main-body {
  width: 100%;
  height: 100%;
  background-color: #342f7d;
  margin-bottom: 40px;
}

.main-bodys {
  width: 100%;
  height: 100%;
  background-color: #342f7d;
  margin-bottom: 40px;
  padding: 20px 28px;
  border-radius: 2px;
}

.text-center {
  text-align: center;
}

.logo-size {
  width: 28%;
}
.d-flex {
  display: flex;
}
.w-100 {
  width: 100% !important;
}
.ml-28 {
  margin-left: 30px;
}
.p-08 {
  padding: 0px 8px !important;
  text-align: center;
}
.d-flex option {
  width: 100% !important;
}
.display-none {
  display: none;
}
.mr-32 {
  margin-right: 32px;
}
.col-2 {
  width: 40%;
  text-align: center;
  margin-right: 30px;
  margin-left: 15px;
}

.bd-form-tittle {
  font-size: 22px;
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 30px;
  padding-top: 30px;
  color: white;
}

.container {
  max-width: 1100px !important;
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.container-form {
  display: flex;
  flex: 0 0 auto;
  margin-bottom: 30px;
}
/* .col-md-4 {
  width: 50%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
} */

.bd-contact-field input {
  background: #f9f9f9;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 12px 10px;
  width: 100%;
}
.bd-contact-field label {
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
}

.bd-contact-field select {
  background: #f9f9f9;
  border: none;
  border-radius: 2px;
  color: #5e5858;
  font-size: 14px;
  font-weight: 450;
  outline: none;
  padding: 12px 10px;
  width: 100%;
}

.main-selection {
  background: #f9f9f9;
  border: none;
  border-radius: 5px;
  color: #5e5858;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  outline: none;
  padding: 0 20px;
}

.text-area {
  padding-left: 65px;
  padding-right: 30px;
}

.get-start {
  border: none;
  border-radius: 8px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: 50px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  width: 120px;
  background-color: #b6224e;
}

.bd-header-btn {
  margin-top: 40px;
  padding-bottom: 40px;
}

.spinner {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top: 50%;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #b6224e;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
.select-position {
  width: 100%;
  padding-bottom: 10px;
  padding-top: 50px;
}

.select-form {
  text-align: left;
  padding-left: 30px;
}
.form-reponsive-file {
  background: #f9f9f9;
  border: none;
  border-radius: 5px;
  color: black;
  font-size: 16px;
  font-weight: 550;
  outline: none;
  padding: 0 20px;
  height: 37px;
  padding-top: 10px;
  width: 90%;
}
.align-items-center {
  align-items: center;
}
.text-select {
  color: white;
  text-align: left;
  margin-top: 26px;
}

@media only screen and (min-width: 350px) and (max-width: 600px) {
  .container-form {
    flex-direction: column;
  }
  .col-md-4 {
    width: 100%;
    margin-top: 20px;
  }
  .select-position {
    width: 100%;
  }
  .select-form {
    padding-left: 0px;
    text-align: center;
  }
  .main-selection {
    width: 80%;
  }
  .main-body {
    width: 100%;
    height: 100%;
  }
  .form-reponsive-file {
    height: 37px;
    padding-top: 10px;
    width: 70%;
  }
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .bd-header-btn {
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 991px) {
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 420px) {
  .bd-contact-field select {
    width: 80%;
  }
  .main-selection {
    width: 80%;
  }
  .text-area {
    padding: 0px 0px 0px 40px;
    margin-top: 0px;
    width: 80%;
  }
}

@media only screen and (min-width: 421px) and (max-width: 550px) {
  .bd-contact-field select {
    width: 78%;
  }
  .main-selection {
    width: 78%;
  }
  .text-area {
    padding: 0px 0px 0px 50px;
    margin-top: 0px;
    width: 80%;
  }
}
@media only screen and (min-width: 551px) and (max-width: 600px) {
  .bd-contact-field select {
    width: 76%;
  }
  .main-selection {
    width: 76%;
  }
  .text-area {
    padding: 0px 0px 0px 70px;
    margin-top: 0px;
    width: 80%;
  }
}
.text-file {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-left: 30px;
  font-size: 14px;
  color: white;
  font-weight: 400;
}
.form-info li {
  font-size: 15.5px;
  color: white;
  line-height: 27px;
  margin-bottom: 15px;
  letter-spacing: 0.2px;
  opacity: 0.98;
}
.form-info {
  padding: 15px 35px;
  list-style: none;
}
.form-info strong {
  font-size: 16px;
}
.p-3 {
  padding: 15px;
}
.pr-10 {
  padding-right: 10px;
}
.line-2 {
  line-height: 2;
  color: #b6224e;
}
.info-box {
  width: 100%;
  height: 100%;
  background-color: #342f7d;
  margin-bottom: 40px;
  border-radius: 2px;
}
.text-right {
  text-align: right;
}
.btn-under {
  background-color: #b6224e;
  border: none;
  border-radius: 10px;
  box-shadow: none;
  color: #fff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 38px;
  text-transform: uppercase;
  margin: 15px 0px 30px;
}
.pl-10 {
  padding-left: 10px;
}
.text-red {
  color: #b6224e;
  text-decoration: none;
  font-weight: 600;
}
.css-13cymwt-control , .css-t3ipsp-control {
  height: 50px;
  border-radius: 2px !important;
}

.smiley-face {
  font-size: 25px;
  margin-bottom: 0;
}
.form-type {
  background-color: #c32948;
  border-radius: 5px;
  color: #fff;
  padding: 12px 16px;
  text-align: center;
}
.red {
  border-color: red !important;
}
.purple {
  border-color: #5c0385 !important;
}
.orange {
  border-color: orange !important;
}
.bd-position-popup .swal2-popup {
  width: 630px;
}
.bd-position-popup .swal2-html-container  {
  font-size: 17px;
}
.bd-position-popup .swal2-confirm  {
  background-color: rgb(253, 215, 150);
  color: #5c0385;
  text-align: center;
}
.bd-position-popup .swal2-footer{
  text-align: center;
}
.bd-position-popup .swal2-title {
  font-size: 20px;
}